<template>
    <div>
        <b-overlay :show="loadDimension" rounded="sm">

            <div class="container_site">
                <div class="ubication_dimension">
                    <p class="ubication_dimension--home">Inicio</p>
                    <img src="@/assets/img/arrow-breadcrumb.svg" class="ubication_dimension--breadcrumb">
                    <p class="ubication_dimension--name_dimension">{{dimension.name_dimension}}</p>
                </div>
                <div class="container_dimension mb-5">      
                    <div class="container_dimension__menu">
                        <div class="container_dimension__menu--header">
                            <div class="container_dimension__menu--btnBack" :style="{'background-color': dimension.color_dimension}" @click="$router.push({name: 'Home'})">
                                <div class="btnBack__arrow">
                                    <img src="@/assets/img/arrow-back.svg">
                                </div>
                                <div class="btnBack__text">Regresar a dimensiones</div>
                            </div>
                            <div class="container_dimension__menu--dimension">
                                DIMENSIÓN
                            </div>
                            <div class="container_dimension__menu--title">
                                {{dimension.name_dimension}}
                            </div>
                        </div>
                        <div class="container_dimension__menu--list">
                            <div class="container_dimension__item" v-for="(value, key) in segments" :key="key">
                                <div class="container_dimension__item--link" v-b-toggle="'accordion-'+value.id">
                                    <div class="container_dimension__item--arrow">
                                        <img src="@/assets/img/arrow-close.svg" v-if="!collapseSegments[key].toggle">
                                        <img src="@/assets/img/arrow-gray-right.svg" v-else style="transform: rotate(90deg)">
                                    </div>
                                    <div class="container_dimension__item--text" :style="{'color': collapseSegments[key].toggle ? '#383838' : '#707070'}">
                                        {{value.name}}
                                    </div>
                                </div>
                                <b-collapse :id="'accordion-'+value.id" accordion="my-accordion" class="container_dimension__item--collapse">
                                    <a :href="'#Category-'+value.id" class="item_segment" :style="hoverCategory" v-for="(value, key) in value.tags.categories" :key="key">{{value.name}}</a>
                                </b-collapse>
                            </div>
                            
                        </div>
                    </div>
                    <div class="container_dimension__content">
                        <div class="container_dimension__content--card">
                            <div class="container_dimension__content--imgDimension" :style="{'background-image': `url(${dimension.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}">
                            </div>
                            <div class="container_dimension__content--description" :style="{'background-color': dimension.color_dimension}">
                                <div class="container_dimension__content--title">
                                    {{dimension.name_dimension}}
                                </div>
                                <div class="container_dimension__content--text">
                                    {{dimension.text_dimension}}
                                </div>
                            </div>
                        </div>
                        <div class="container_dimension__content--segments" v-for="(Segment, keySegment) in segments" :key="keySegment">
                            <div class="container_dimension__content--title_segment" :style="{'background-color': dimension.color_dimension}">
                                {{Segment.name}}
                            </div>
                            <div class="container_dimension__content--info_item" v-for="(Category, keyCategory) in Segment.tags.categories" :key="keyCategory">
                                <div class="container_dimension__content--info_title" :id="'Category-'+Category.id">{{Category.name}}</div>
                                <div class="container_dimension__content--info_text">
                                    {{Category.description}}
                                </div>
                                
                                <div class="container_dimension__content--info_graph" v-for="(Graph, keyGraph) in Category.graphs.tables" :key="keyGraph">
                                    <div class="container_dimension__content--title_graph">
                                        {{Graph.name}}
                                    </div>
                                    <div class="container_dimension__content--description_graph">
                                        {{Graph.description}}
                                    </div>
                                    <Graphs
                                        :type="Graph"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container_dimension_mobile mb-3" :style="{'border-top': '10px solid '+dimension.color_dimension}">
                    <div class="container_dimension_mobile--title_dimension" v-if="!openMenuDimension">
                        <div style="width: 98%">{{dimension.name_dimension}}</div>
                        <a 
                            href="#" 
                            class="container_dimension_mobile--icon_menu" 
                            @click="openMenuDimension = !openMenuDimension"
                        >
                            <MenuIcon />
                        </a>
                    </div>

                    <div class="container_dimension_mobile--title_dimension" v-if="openMenuDimension">
                        <div style="width: 100%" v-if="dimension.subcategory">Selecciona una secretaría</div>
                        <div style="width: 100%" v-else>Selecciona una dimensión</div>
                        <a 
                            href="#" 
                            class="container_dimension_mobile--icon_menu" 
                            @click="openMenuDimension = !openMenuDimension"
                        >
                            <ArrowUpIcon width="18.828" height="10.414" color="#000000" />
                        </a>
                    </div>

                    <DimensionMovileList v-if="openMenuDimension" :subcategory="dimension.subcategory" />
                    
                </div>
                <div class="container_dimension_mobile__card_segments--mobile" v-for="(Segment, keySegment) in segments" :key="keySegment">
                    <div class="container_dimension_mobile__card_segment--title_segment">
                        {{Segment.name}}
                    </div>
                    <div v-if="Segment.description" class="container_dimension_mobile__card_segment--description_segment" v-html="Segment.description">

                    </div>

                    <div v-else class="container_dimension_mobile__card_segment--description_segment" style="overflow: auto !important;height: 300px !important;">

                        <table v-if="Segment.infoTable">
                            <tr>
                                <th>Indicador</th>
                                <th>Desagregación geográfica</th>
                                <th>Unidad de medida</th>
                                <th>2018</th>
                                <th>2019</th>
                                <th>2020</th>
                                <th>2021</th>
                                <th>2022</th>
                                <th>2023</th>
                                <th>Info</th>
                            </tr>
                            <tr v-for="(item, index) in Segment.infoTable" >
                                    <td>{{ item[0]}}</td>
                                    <td>{{ item[3]}}</td>
                                    <td>{{ item[4]}}</td>

                                    <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{ isNaN(item[5]) ? item[5] : Number(item[5]).toLocaleString() }}</td>
                                    <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[6])  ? item[6] : Number(item[6]).toLocaleString() }}</td>
                                    <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[7])  ? item[7] : Number(item[7]).toLocaleString() }}</td>
                                    <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[8])  ? item[8] : Number(item[8]).toLocaleString() }}</td>
                                    <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[9])  ? item[9] : Number(item[9]).toLocaleString() }}</td>
                                    <td v-if="item[4] == 'Pesos' " style="padding: 5px;">{{isNaN(item[9])  ? item[9] : Number(item[10]).toLocaleString() }}</td>

                                    <td v-if="item[4] != 'Pesos' ">{{ item[5]}}</td>
                                    <td v-if="item[4] != 'Pesos' ">{{ item[6]}}</td>
                                    <td v-if="item[4] != 'Pesos' ">{{ item[7]}}</td>
                                    <td v-if="item[4] != 'Pesos' ">{{ item[8]}}</td>
                                    <td v-if="item[4] != 'Pesos' ">{{ item[9]}}</td>
                                    <td v-if="item[4] != 'Pesos' ">{{ item[10]}}</td>

                                <td>
                                    <button 
                                    :style="{'background-color':dimension.color_dimension }" 
                                    class="boton notranslate"
                                    @click="goToGraphTableModal(item,Segment.name,index,Segment.id,false)"
                                    >i</button>

                                    <button v-if="Segment.id == 203 && index == 5"
                                    :style="{'background-color':dimension.color_dimension }" 
                                    class="boton notranslate"
                                    @click="goToGraphTableModal(item,Segment.name,index,Segment.id,true)"
                                    >A</button>
                                </td>
                            </tr>
                        </table>

                    </div>

                    <div class="container_dimension_mobile__categories">
                        <div class="container_dimension_mobile__categories--category" :style="hoverCategory" v-for="(Category, keyCategory) in Segment.tags.categories" :key="keyCategory" @click="$router.push({name: 'Category', params: {id: id, Segment_name: Segment.name}, query: {Category: Category.id, Segment: Segment.id, infoTable: Category.infoTable}})">
                            <div class="container_dimension_mobile__categories--category__check_text">
                                <div class="container_dimension_mobile__categories--category__check" :style="hoverCategory">
                                    <img src="@/assets/img/icono-check-w.svg">
                                </div>
                                <p>{{Category.name}}</p>
                            </div>
                            <img src="@/assets/img/arrow-btn.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="container_btn_actions mb-5">
                    <div class="container_btn_actions__btn_back" @click="$router.go(-1)">
                        <img src="@/assets/img/icono-regresar.svg" alt="">
                        <p>Regresar</p>
                    </div>
                    <div class="container_btn_actions__btn_home" @click="$router.push({name: 'Home'})">
                        <img src="@/assets/img/icono-inicio.svg" alt="">
                        <p>Inicio</p>
                    </div>
                </div>
                
            </div>
        </b-overlay>
        <Graph-Table-Modal v-if="showGraph==true"
            :product="product"
            :color="dimension.color_dimension"
            :segmentName="segmentName"
            :indexGraphTableModal="indexGraphTableModal"
            :segmentId="segmentId"
            :showExcel="showExcel"
            @cancelProduct="showProductOut"
        />
    </div>
</template>
<script>

export default {
    data() {
        return {
            id:this.$route.params.id,
            dimension:[],
            segments: [],
            collapseSegments: [],
            loadDimension: true,
            openMenuDimension: false,
            product:[],
            segmentName: '',
            showGraph:false,
            indexGraphTableModal:'',
            segmentId:'',
            showExcel:false
        }
    },
    methods:{
      goToGraphTableModal(product,name,index,id,excel){
     
        this.product=product
        this.indexGraphTableModal=index
        this.segmentId=id
        this.showExcel=excel
        this.segmentName = name
        // this.modalCategory = category
        this.showGraph=true
      },

      showProductOut(value) {
        this.showProduct = value;
        this.showGraph = value
        // this.showGraphCategory = value
      },
        getDimension(){
            this.$http.get('dimensions/'+this.id).then(response =>{
                this.getSegments();
                //Console.Log('Dimensión consultada: ', response.body)
                this.dimension = response.body;
                
            }).catch(error => {
                //Console.Log('Error al consultar la dimensión: ', error)
            })
        },
        getSegments(){
            this.$http.get('architecture_tags/dimension/'+this.id).then(response =>{
                //Console.Log('Segmentos consultados: ', response.body)
                this.segments = response.body.Segments;

                console.log('this.segments', this.segments)
                this.loadDimension = false;
                response.body.Segments.forEach(element => {
                    this.collapseSegments.push({
                        name: 'accordion-'+element.id,
                        toggle: false
                    });
                });

                this.segments.forEach((element) => {

                    if( element.segment == true){

                    if(element.graphsTable.length > 0){
                        
                        var formater = JSON.parse(element.graphsTable[0].data)

                        element.infoTable = formater

                        // element.infoTable.shift()

                        console.log('element', element)
                    }

                    }

                });
            }).catch(error => {
                //Console.Log('Error al consultar los segmentos: ', error)
            })
        }
    },
    created() {
        this.getDimension();
    },
    computed: {
        hoverCategory(){
            return {
                '--hover-color' : this.dimension.color_dimension
            }
        }
    },
    mounted(){

        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            this.collapseSegments.forEach((element, index) => {
                if (element.name == collapseId) {
                    this.collapseSegments[index].toggle = isJustShown
                }
            });
        });

    }
}
</script>

<style scoped>
.button_expand_table {
  width: 40% !important;
  margin-left: 30% !important;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td{
  
  text-align: left;
  padding: 8px;
  font-size: 14px;
  height: 100px;
  text-align: center;
  border-right: 1px solid #ccc;
}

th{
  background-color: #383838;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
  height: 60px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #F4F4F4;
  
}

.boton{
  width:30px;
  height:30px;
  margin: 5px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size:19px;
  color: #FFFFFF;

}
.boton:hover{
  opacity: 0.50;
  -moz-opacity: .50;
  filter:alpha (opacity=50);
}
.boton a{
  color:#fff;
  text-decoration:none;
  padding:5px 5px 5px 0;
}

</style>